.dx-popup-content .body {
    display: flex;
    flex-direction: row;
}

.dx-popup-content .sideBar {
    flex: 0 0 1;
    width: 200px;
    padding: 10px;
    height: 100vh;
    background: #e1e1e1;
}

.dx-popup-content .sideBarItem {
    border: 2px solid #000;
    border-radius: 3px;
    margin-top: 10px;
    background: #ccc;
}

.dx-popup-content .page {
    padding: 0 20px;
    margin: 20px;
    border: 2px solid green;
}

.dx-popup-content .dropZone {
    flex: 0 0 auto;
    height: 0.5rem;
    transition: 200ms all;
}

.dx-popup-content .dropZone:nth-of-type(2n) {
    display: none;
}

.dx-popup-content .dropZone.horizontalDrag {
    width: 0.5rem;
    height: auto;
}

.dx-popup-content .dropZone:not(.horizontalDrag).isLast {
    flex: 1 1 auto;
}

.dx-popup-content .dropZone.active,
.dx-popup-content .trashDropZone.active {
    background: #00a2ff;
    transition: 100ms all;
}


.dx-popup-content .component {
    padding: 10px;
    border: 2px dashed black;
    padding: 0.2rem 0.2rem;
    background-color: "white";
    cursor: "move"
}

.dx-popup-content .column {
    border: 2px dashed blue;
    flex: 1 1 100%;
    padding: 10px;
}

.dx-popup-content .columns {
    display: flex;
    padding: 5px 0;
}

.dx-popup-content .columnContainer {
    display: flex;
}

.dx-popup-content .base {
    padding: 0.2rem 0.2rem;
    background-color: white;
    cursor: move;
}

.dx-popup-content .row {
    border: 2px dashed red;
    padding: 0;
}

.dx-popup-content .trashDropZone {
    position: relative;
    text-align: center;
    padding: 20px 0;
    margin: 0 auto;
    border: 2px solid black;
    display: flex;
    align-items: center;
    justify-content: center;
}

.dx-popup-content .pageContainer {
    display: flex;
    flex: 1;
    flex-direction: column;
}


del {
    text-decoration: none;
    background-color: red;
    color: white;
    margin-right: 2px;
}

ins {
    text-decoration: none;
    background-color: green;
    color: white;
    margin-right: 2px;
}

#diffArea img {
    width: 100%;
}

.dx-datagrid .dx-link {
    text-decoration: none;
}
.dx-filemanager .dx-filemanager-thumbnails
  .dx-filemanager-thumbnails-view-port
  .dx-filemanager-thumbnails-item
  .dx-filemanager-thumbnails-item-content
  .dx-filemanager-thumbnails-item-thumbnail {
    border: 0;
    height: 100px;
    width: 100px;
    font-size: 90px;
}
.dx-filemanager .dx-filemanager-thumbnails .dx-filemanager-thumbnails-view-port .dx-filemanager-thumbnails-item{
    width:100px;
    height:unset;
}